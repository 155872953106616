"use client";
import type { PropsWithChildren } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createContext } from "@/helpers/createContext";
import useLocalStorage from "@/hooks/useLocalStorage";
import useAxiosQuery from "@/hooks/useMyQuery";

export interface CurrencyIF {
  code: string; // USD
  name: string; // US Dollar
  symbol: string; // $
  rate: number; // 1
  icon?: string;
}

interface CurrencyRateIF {
  code: string; // VND
  value: number; // 23447.024866
}

interface ContextProps {
  currentCurrency: CurrencyIF;
  listCurrencies: CurrencyIF[];
  currencyRate: number;
  changeCurrency: (currency: string) => any;
}

const [Provider, useCurrencyContext] = createContext<ContextProps>();

export { useCurrencyContext };

const defaultCurrency: CurrencyIF = {
  code: "USD",
  name: "US Dollar",
  symbol: "$",
  rate: 1,
};

export default function CurrencyProvider({ children }: PropsWithChildren) {
  const [query, switchQuery] = useLocalStorage<string>("_klarda_currency", "");

  const { data: currencyData } = useAxiosQuery<{
    data: { [p: string]: CurrencyIF };
  }>({
    url: "v1/currency",
  });

  const { data: rateData } = useAxiosQuery<{
    data: { [p: string]: CurrencyRateIF };
  }>(
    {
      url: "v1/currency/rates",
    },
    {
      queryKey: ["v1/currency/rates"],
      refetchInterval: 60000,
    },
  );

  const listCurrencies = useMemo(
    () =>
      [
        defaultCurrency,
        ...Object.keys(currencyData?.data || {})
          .reduce((sum, key) => [...sum, (currencyData?.data || {})[key]], [] as CurrencyIF[])
          .filter(c => c.code !== defaultCurrency.code),
      ].map(c => ({
        ...c,
        rate: (rateData?.data || {})[c.code]?.value ?? 1,
      })),
    [currencyData, rateData],
  );

  const [currentCurrency, setCurrentCurrency] = useState<CurrencyIF>(defaultCurrency);

  useEffect(() => {
    const cur = query || defaultCurrency.code;
    setCurrentCurrency(listCurrencies.find(c => c.code === cur) || defaultCurrency);
  }, [listCurrencies, query]);

  const currencyRate = useMemo(() => currentCurrency.rate, [currentCurrency]);

  const changeCurrency = useCallback((currency: string) => switchQuery(currency), [switchQuery]);

  return (
    <Provider
      value={{
        currentCurrency,
        listCurrencies,
        currencyRate,
        changeCurrency,
      }}
    >
      {children}
    </Provider>
  );
}
