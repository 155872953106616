import type { CSSProperties, ReactNode } from "react";
import React from "react";
import classNames from "classnames";
import Typography from "antd/lib/typography";

interface CardProps {
  title?: ReactNode;
  titleLevel?: 1 | 2 | 3 | 4 | 5; // New prop for SEO heading levels
  extra?: ReactNode;
  children: ReactNode;
  className?: string;
  border?: boolean;
  styles?: {
    body?: CSSProperties;
    container?: CSSProperties;
    header?: CSSProperties;
    title?: CSSProperties;
  };
  size?: "small" | "default";
  hoverable?: boolean;
}

const MyCard: React.FC<CardProps> = ({
  title,
  titleLevel = 5, // Default to h5 to maintain backward compatibility
  extra,
  children,
  className,
  border,
  styles = {},
  size = "default",
  hoverable = false,
}) => (
  <div
    className={classNames(
      "bg-white",
      {
        "transition-shadow duration-300 ease-in-out hover:shadow-lg cursor-pointer": hoverable,
      },
      className,
    )}
    style={styles.container}
  >
    {(title || extra) && (
      <div
        className={classNames(
          "flex justify-between items-center gap-3",
          size === "small" ? "min-h-[40px] pb-2 text-sm" : "pb-4",
          border === false ? "" : "border-b border-gray-200",
        )}
        style={styles.header}
      >
        {title && (
          <Typography.Title level={titleLevel} className="m-0 py-1 md:w-full text-sm lg:text-lg" style={styles.title}>
            {title}
          </Typography.Title>
        )}
        {extra && <div>{extra}</div>}
      </div>
    )}
    <div
      className={classNames({
        "py-4": !styles.body?.padding && styles.body?.padding !== 0,
      })}
      style={styles.body}
    >
      {children}
    </div>
  </div>
);

export default MyCard;
