"use client";

import { Button, Tag } from "antd/lib";
import { RiseOutlined } from "@ant-design/icons";
import ChartMixedDollar from "@/svg/ChartMixedDollar";
import useAxiosQuery from "@/hooks/useMyQuery";
import { KLARDA_HOME_URL } from "@/constant/apiPath";

interface TagItem {
  tag: string;
  count: number;
}
export default function TrendingTag() {
  // TODO: call api get trending tags
  const { data, isLoading } = useAxiosQuery<{ data: TagItem[] }>({
    url: `/v1/blog/vi/top-tags`,
    method: "get",
  });

  return (
    <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-10 py-2 sm:py-[10px] w-full">
      {/* Desktop & Tablet View */}
      <div className="hidden sm:flex justify-between items-center w-full">
        <div className="flex gap-2 sm:gap-3 items-center overflow-x-auto">
          <Button
            icon={<RiseOutlined className="text-white" />}
            shape="circle"
            size="small"
            style={{ background: "linear-gradient(223.99deg, #BD01FF 34.14%, #0157FF 85.38%)" }}
            className="flex-shrink-0 size-6"
          />

          {data?.data?.slice(0, 5)?.map(item => (
            <Tag
              className="cursor-pointer whitespace-nowrap flex-shrink-0"
              key={item.tag}
              onClick={() => window.open(`/tin-tuc/search?tag=${item.tag}` || "")}
            >
              #{item?.tag}
            </Tag>
          ))}
        </div>
        <Button
          type="primary"
          className="flex items-center gap-1 flex-shrink-0 ml-4"
          onClick={() => window.open(`${KLARDA_HOME_URL}/vi/markets` || "", "_blank")}
        >
          <ChartMixedDollar />
          Giá Cryto Hôm Nay
        </Button>
      </div>

      {/* Mobile View */}
      <div className="sm:hidden flex flex-col gap-3 w-full">
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center gap-2">
            <Button
              icon={<RiseOutlined className="text-white" />}
              shape="circle"
              size="small"
              style={{ background: "linear-gradient(223.99deg, #BD01FF 34.14%, #0157FF 85.38%)" }}
              className="flex-shrink-0 size-6"
            />
            <span className="text-sm text-gray-600">Trending</span>
          </div>
          <Button
            type="primary"
            size="small"
            className="flex items-center gap-1"
            onClick={() => window.open(`${KLARDA_HOME_URL}/vi/markets` || "", "_blank")}
          >
            <ChartMixedDollar className="w-4 h-4" />
            Giá Crypto
          </Button>
        </div>

        <div className="flex gap-2 overflow-x-auto pb-1 w-full scrollbar-hide">
          {data?.data?.slice(0, 3)?.map(item => (
            <Tag
              className="cursor-pointer whitespace-nowrap"
              key={item?.tag}
              onClick={() => window.open(`/tin-tuc/search?tag=${item.tag}` || "")}
            >
              #{item?.tag}
            </Tag>
          ))}
        </div>
      </div>
    </div>
  );
}

