"use client";

import ActiveUser from "@/app/components/HeaderActiveUser";
import UserPopover from "@/app/components/UserSetting";
import { IMAGE_CDN, KLARDA_HOME_URL } from "@/constant/apiPath";
import { useAuth } from "@/context/AuthContext";
import KlardaComLogo from "@/svg/KlardaComLogo";
import KlardaLogo1 from "@/svg/KlardaLogo1";
import { CloseOutlined, HomeOutlined, SearchOutlined } from "@ant-design/icons";
import { Avatar, Button, Menu } from "antd";
import { motion } from "framer-motion";
import { isEmpty } from "lodash";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SearchModal from "./SearchModal";

// Types
interface MenuToggleProps {
  toggle: () => void;
  isOpen: boolean;
}

interface PathProps {
  d?: string;
  variants?: any;
  transition?: any;
  animate?: any;
}

interface MobileMenuProps {
  isMenuOpen: boolean;
  current: string;
  menuItems: MenuItem[];
  handleMenuClick: (path: string) => void;
  authState: string;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface DesktopMenuProps {
  current: string;
  menuItems: MenuItem[];
  handleMenuClick: (path: string) => void;
}

interface MenuItem {
  key: string;
  label: string;
  children?: MenuItem[];
}

// Navigation items
const navigationItems: MenuItem[] = [
  { key: "tin-tuc", label: "Tin Tức" },
  { key: "insight", label: "Insight" },
  { key: "report", label: "Market Reports" },
  { key: "review", label: "Klarda Review" },
  { key: "crypto", label: "Học đầu tư Crypto" },
];

// Utility Components
const Path: React.FC<PathProps> = props => (
  <motion.path fill="transparent" strokeWidth="3" stroke="hsl(0, 0%, 18%)" strokeLinecap="round" {...props} />
);

const MenuToggle: React.FC<MenuToggleProps> = ({ toggle, isOpen }) => (
  <button onClick={toggle} className="lg:hidden bg-transparent border-none">
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" },
        }}
        animate={isOpen ? "open" : "closed"}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
        animate={isOpen ? "open" : "closed"}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" },
        }}
        animate={isOpen ? "open" : "closed"}
      />
    </svg>
  </button>
);

// User Related Components
const UserAvatar: React.FC<{ avatarUrl: string }> = ({ avatarUrl }) => (
  <div className="user-avatar-wrapper cursor-pointer ml-1" style={{ zIndex: 10 }}>
    <Avatar src={avatarUrl} className="!border-2 !border-solid !border-white z-10" size="default" />
  </div>
);

const KlardaButton: React.FC = () => (
  <Button
    href={KLARDA_HOME_URL}
    target="_blank"
    size="large"
    className="bg-secondary-1 text-primary border-none flex items-center font-semibold rounded-xl"
  >
    Klarda.com
    <HomeOutlined />
  </Button>
);

const LoginButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button type="primary" size="large" className="text-white border-none rounded-xl" onClick={onClick}>
    <div className="flex gap-2 items-center">
      <KlardaLogo1 className="size-5" /> Đăng nhập
    </div>
  </Button>
);

const UserLoggedAction: React.FC = () => {
  const { user } = useAuth();
  const userAvt = useMemo(
    () =>
      !isEmpty(user?.profile_information?.avatar)
        ? `${IMAGE_CDN}${user?.profile_information?.avatar}`
        : "https://api.dicebear.com/7.x/miniavs/svg?seed=2",
    [user?.profile_information?.avatar],
  );

  return (
    <>
      <style>{`
        @keyframes rotate-gradient {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        .user-avatar-wrapper {
          position: relative;
          display: inline-block;
        }
        .user-avatar-wrapper::before {
          content: '';
          position: absolute;
          top: -3px; left: -3px; right: -3px; bottom: -3px;
          border-radius: 50%;
          background: conic-gradient(from 0deg, #ff00ff, #00ff00, #00ffff, #ff00ff);
          animation: rotate-gradient 10s linear infinite;
        }
        .user-avatar-wrapper::after {
          content: '';
          position: absolute;
          top: -1px; left: -1px; right: -1px; bottom: -1px;
          background: white;
          border-radius: 50%;
        }
      `}</style>
      <KlardaButton />
      <ActiveUser />
      <UserPopover>
        <div
          className="user-avatar-wrapper cursor-pointer ml-1"
          style={{ zIndex: 10 }}
          onClick={e => e.stopPropagation()}
        >
          <Avatar src={userAvt} className="!border-2 !border-solid !border-white z-10" size="default" />
        </div>
      </UserPopover>
    </>
  );
};

const GuestAction: React.FC = () => {
  const { loginHomePage } = useAuth();

  return (
    <div className="flex gap-4">
      <ActiveUser />
      <KlardaButton />
      <LoginButton onClick={loginHomePage} />
    </div>
  );
};

const MobileMenu: React.FC<MobileMenuProps> = React.memo(
  ({ isMenuOpen, current, menuItems, handleMenuClick, authState, setIsMenuOpen }) => {
    const { logout, loginHomePage } = useAuth();

    const mobileMenuItems = useMemo(
      () => [
        ...menuItems,
        ...(authState === "logged"
          ? [
              {
                key: "acc-setting",
                label: (
                  <Button type="primary" className="border-none w-full">
                    Cài đặt tài khoản
                  </Button>
                ),
              },
              {
                key: "signout",
                label: (
                  <Button type="primary" className="w-full border-none text-center" onClick={logout}>
                    Đăng xuất
                  </Button>
                ),
              },
            ]
          : [
              {
                key: "sign-in",
                label: (
                  <Button type="primary" className="w-full text-white border-none text-center" onClick={loginHomePage}>
                    <div className="flex justify-center gap-2 items-center">
                      <KlardaLogo1 /> Đăng nhập
                    </div>
                  </Button>
                ),
              },
            ]),
      ],
      [authState, logout, loginHomePage, menuItems],
    );

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: isMenuOpen ? 1 : 0 }}
        className={`fixed inset-0 bg-white z-[60] lg:hidden ${isMenuOpen ? "block" : "hidden"}`}
      >
        <div className="flex flex-col h-full">
          <div className="sticky top-0 flex justify-between p-2 items-center bg-white border-b">
            <Link href={"/"}>
              <KlardaComLogo className="ml-3" />
            </Link>
            <Button
              type="text"
              icon={<CloseOutlined className="text-xl" />}
              onClick={() => setIsMenuOpen(false)}
              className="hover:bg-gray-100"
            />
          </div>
          <div className="flex-1 overflow-y-auto">
            <Menu
              selectedKeys={[current]}
              mode="vertical"
              className="border-none"
              items={mobileMenuItems}
              onClick={({ key }) => handleMenuClick(key)}
            />
          </div>
        </div>
      </motion.div>
    );
  },
);
MobileMenu.displayName = "MobileMenu";

const DesktopMenu: React.FC<DesktopMenuProps> = React.memo(({ current, menuItems, handleMenuClick }) => {
  return (
    <div className="hidden lg:block flex-1">
      <Menu
        selectedKeys={[current]}
        mode="horizontal"
        className="border-none flex-grow font-semibold text-base justify-center"
        items={menuItems}
        onClick={({ key }) => handleMenuClick(key)}
      />
    </div>
  );
});
DesktopMenu.displayName = "DesktopMenu";

// Main Header Component
const Header: React.FC = () => {
  const router = useRouter();
  const pathname = usePathname();
  const { authState } = useAuth();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openSearchModal, setOpenSearchModal] = useState(false);

  const currentPath = useMemo(() => {
    const pathParts = pathname.split("/");
    return pathParts?.[1];
  }, [pathname]);

  const handleMenuClick = useCallback(
    (path: string) => {
      const urlPattern = /^https?:\/\//i;
      if (urlPattern.test(path)) {
        window.open(path);
      } else {
        setIsMenuOpen(false);
        router.push(`/${path}`);
      }
    },
    [router],
  );

  const handleMenuClickUnRedirect = useCallback(
    (path: string) => {
      const urlPattern = /^https?:\/\//i;
      if (urlPattern.test(path)) {
        window.open(path, "_self");
      } else {
        setIsMenuOpen(false);
        router.push(`/${path}`);
      }
    },
    [router],
  );

  const toggleMenu = useCallback(() => setIsMenuOpen(prev => !prev), []);

  return (
    <>
      <SearchModal open={openSearchModal} onClose={() => setOpenSearchModal(false)} />
      <div className="bg-white w-full sticky top-0 z-50 transition-all duration-300">
        <header className={`w-full max-w-[1440px] mx-auto`}>
          <div className="flex items-center px-4 py-1 lg:px-6 gap-4 justify-between">
            <KlardaComLogo onClick={() => handleMenuClick("/")} className="cursor-pointer w-fit" />
            <MobileMenu
              isMenuOpen={isMenuOpen}
              current={currentPath}
              menuItems={navigationItems}
              handleMenuClick={handleMenuClickUnRedirect}
              authState={authState}
              setIsMenuOpen={setIsMenuOpen}
            />
            <DesktopMenu
              current={currentPath}
              menuItems={navigationItems}
              handleMenuClick={handleMenuClickUnRedirect}
            />
            <div className="flex gap-4 items-center">
              <Button
                icon={<SearchOutlined />}
                shape="circle"
                className="size-[34px]"
                onClick={() => setOpenSearchModal(true)}
              />
              <div className="hidden md:flex gap-4 items-center">
                {authState === "logged" ? <UserLoggedAction /> : <GuestAction />}
              </div>
              <MenuToggle toggle={toggleMenu} isOpen={isMenuOpen} />
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default React.memo(Header);
