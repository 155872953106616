import { Tag } from "antd/lib";
import classNames from "classnames";
import React from "react";

const rankDict: Record<string, { color: string }> = {
  A: {
    color: "purple",
  },
  B: {
    color: "geekblue",
  },
  C: {
    color: "cyan",
  },
  D: {
    color: "gold",
  },
  E: {
    color: "red",
  },
  default: {
    color: "default",
  },
};

export default function RankLabel({ label }: { label: string }) {
  return (
    <Tag color={rankDict[label]?.color || rankDict.default.color} className={classNames("py-0.25 font-bold")}>
      {label}
    </Tag>
  );
}
