"use client";

import Image from "next/image";
import img1 from "@/asset/images/image.png";
import img2 from "@/asset/images/image_2.png";
import img1Mb from "@/asset/images/image_mb.png";
import img2Mb from "@/asset/images/image_2_mb.png";
import { Button, Typography } from "antd/lib";
import Link from "next/link";
import classNames from "classnames";

export default function AdsBanner({ isSmallSize = false }: { isSmallSize?: boolean }) {
  return (
    <div
      className={classNames(
        "grid grid-cols-1 lg:grid-cols-12 max-w-[1312px] mx-auto p-4 lg:p-0",
        isSmallSize ? "gap-2" : "gap-6",
      )}
    >
      <div className="col-span-1 lg:col-span-8">
        <div className="lg:hidden">
          <Image src={img1Mb} alt="Mobile Banner" className="w-full h-auto rounded-xl" priority />
        </div>
        <Typography.Text className="text-xs text-right ml-auto hidden lg:flex justify-end" type="secondary">
          Advertising
        </Typography.Text>
        <Link href="https://bubbles.klarda.com" target="_blank">
          <div
            className={classNames(
              "hidden lg:flex flex-col bg-no-repeat bg-center bg-contain text-white p-4 rounded-xl",
              isSmallSize ? "gap-2 md:h-[170px]" : "gap-2 lg:gap-6 md:h-[220px] pt-4 lg:pt-8",
            )}
            style={{ backgroundImage: `url(${img1.src})` }}
          >
            <Typography.Text
              className={classNames(
                "text-[#170F49] max-w-[50%]",
                isSmallSize ? "text-xs lg:text-xl" : "text-xs lg:text-3xl lg:leading-8",
              )}
              strong
            >
              Live price visualization with Klarda bubble
            </Typography.Text>
            <Button size="large" className="mb-4 lg:mb-0 rounded-full w-[120px] text-white font-bold bg-primary">
              Get started
            </Button>
          </div>
        </Link>
      </div>

      <div className="col-span-1 lg:col-span-4">
        <Link href="https://dashboard.klarda.com" target="_blank">
          <div className="lg:hidden">
            <Image src={img2Mb} alt="Mobile Banner" className="w-full h-auto rounded-xl" priority />
          </div>

          <div
            className={classNames(
              "hidden lg:flex flex-col bg-no-repeat bg-center mt-[17px] bg-contain text-white p-4 rounded-xl",
              isSmallSize ? "gap-2" : "gap-6 pt-8",
            )}
            style={{ backgroundImage: `url(${img2.src})`, height: isSmallSize ? "170px" : "220px" }}
          >
            <Typography.Text
              className={classNames(
                "text-white max-w-[100%]",
                isSmallSize ? "text-xs lg:text-xl" : "text-2xl lg:text-3xl leading-8",
              )}
              strong
            >
              Manage portfolio, optimize your asset growing
            </Typography.Text>
            <Button size="large" className="rounded-full w-[170px] font-bold text-primary">
              Try it now for free
            </Button>
          </div>
        </Link>
      </div>
    </div>
  );
}
