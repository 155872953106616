"use client";
import Typography from "antd/lib/typography";
import Card from "antd/lib/card";
import Divider from "antd/lib/divider";
import Link from "next/link";
import { FaIcon } from "./faIcon";
import Image from "next/image";
import React from "react";
import KlardaComLogo from "@/svg/KlardaComLogo";
import downloadAndroid from "@/asset/images/download-play-store.svg";
import downloadIos from "@/asset/images/download-app-store.svg";

const Footer = () => (
  <div className="bg-[#ECEFF6]">
    <div className="mx-auto p-6 max-w-[1440px]">
      <div className="pb-6 pt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="flex flex-col gap-2">
          <KlardaComLogo />
          <p className="text-gray-500 text-sm">Stay in the loop!</p>
          <p className="text-gray-500 text-sm">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Follow us on social media for Klarda's latest news and updates.
          </p>
        </div>
        <div className={`grid grid-cols-2 sm:grid-cols-3 gap-6`}>
          {[
            {
              title: "Company",
              items: [
                {
                  title: "About Klarda",
                  url: "https://inside.klarda.com/",
                },
                {
                  title: "Features",
                  url: "https://inside.klarda.com/features",
                },
                {
                  title: "Careers",
                  url: "https://inside.klarda.com/careers",
                },
                {
                  title: "Partners",
                  url: "https://inside.klarda.com/partners",
                },
                {
                  title: "APIs",
                  url: "https://inside.klarda.com/apis",
                },
                {
                  title: "Labs",
                  url: "https://inside.klarda.com/labs",
                },
              ],
            },
            {
              title: "Products",
              items: [
                {
                  title: "Klarda Dashboard",
                  url: "https://dashboard.klarda.com",
                },
                {
                  title: "Klarda Bubble",
                  url: "https://bubbles.klarda.com",
                },
                {
                  title: "Klarda Whisper",
                  url: "https://t.me/klardawhisperbot",
                },
                {
                  title: "Klarda Events",
                  url: "/",
                },
                {
                  title: "Klarda APIs",
                  url: "https://inside.klarda.com/apis",
                },
              ],
            },
            {
              title: "Support",
              items: [
                {
                  title: "FAQ",
                  url: "https://docs.klarda.com",
                },
                {
                  title: "Help Center",
                  url: "https://docs.klarda.com",
                },
              ],
            },
          ].map((cols, index) => (
            <div key={index} className="flex flex-col gap-5">
              <span className="text-sm font-semibold text-colorText">{cols.title}</span>
              {cols.items.map((row, rowIndex) => (
                <a
                  key={rowIndex}
                  href={row.url}
                  target="_blank"
                  className="text-sm text-colorTextSecondary"
                  rel="noopener noreferrer" // For security reasons
                >
                  {row.title}
                </a>
              ))}
            </div>
          ))}
        </div>
      </div>
      <Divider />
      <Card bordered={false} className="shadow-none bg-[#ECEFF6]" classNames={{ body: "p-0" }}>
        <div className="flex w-full flex-wrap  items-end justify-between gap-3">
          <div className="w-full flex-1 flex gap-2 flex-col sm:flex-row">
            <div className="text-wrap sm:text-nowrap text-center sm:text-left">
              <Typography.Title level={4} className="m-0">
                Get our app now!
              </Typography.Title>
              <p>
                Klarda App helps you manage your assets <br className="hidden sm:inline" /> and update market
                information real time!
              </p>
            </div>
            <div className="flex items-center gap-2 justify-center sm:justify-start">
              <div
                className={"cursor-pointer"}
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.klarda.app&pcampaignid=web_share",
                    "_blank",
                  )
                }
              >
                <Image src={downloadAndroid} alt="Download Android" width={183} height={67} />
              </div>
              <div
                className={"cursor-pointer"}
                onClick={() =>
                  window.open("https://apps.apple.com/app/klarda-crypto-and-portfolio/id6471593752", "_blank")
                }
              >
                <Image src={downloadIos} alt="Download iOS" width={183} height={67} />
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/3 xl:w-1/2 flex gap-3 justify-center sm:justify-end h-full items-end">
            <Link href="https://twitter.com/KlardaOfficial" target="_blank">
              <FaIcon name="twitter" className="size-5 text-[#A1A1AA]" />
            </Link>
            <Link href="https://t.me/KlardaAnnouncement" target="_blank">
              <FaIcon name="telegram" className="size-5 text-[#A1A1AA]" />
            </Link>
            <Link href="https://www.facebook.com/klarda.official" target="_blank">
              <FaIcon name="facebook" className="size-5 text-[#A1A1AA]" />
            </Link>
          </div>
        </div>
      </Card>

      <Divider />
      <div className="text-center">
        <Typography.Text type="secondary">
          © 2019 - {new Date().getFullYear()} klarda.com. All rights reserved.
        </Typography.Text>
      </div>
    </div>
  </div>
);

export default Footer;
