import LinkToKlardaApp from "@/app/components/LinkToKlardaApp";
import MyCard from "@/app/components/MyCard";
import MyModal from "@/app/components/MyModal";
import NewsInfo from "@/app/components/NewsInfo";
import { processNewsData } from "@/helpers";
import useAxiosQuery from "@/hooks/useMyQuery";
import type { BlogData, INewsResponse } from "@/models/news";
import KlardacomLogo from "@/svg/KlardaComLogo";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Empty, Input, InputRef, Skeleton, Typography } from "antd/lib";
import Link from "next/link";
import { useRouter } from "next/navigation";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import AdsBanner from "./AdsBanner";
import { debounce } from "lodash";
import { IMAGE_CDN } from "@/constant/apiPath";
import logoBlog from "@/asset/images/logo-blog-klarda.png";

interface SearchModalProps {
  open: boolean;
  onClose: () => void;
}

interface ITrendingData {
  category: string;
  last_24h_price: number;
  logo_uri: string;
  market_cap: number;
  name: string;
  price: number;
  short_description: string;
  symbol: string;
  token_symbol: string;
  volume: number;
}
const convertToNewsResponse = (item: BlogData): INewsResponse => {
  return {
    id: item.id,
    title: item.title,
    description: item.description,
    categories: item.categories.map(category => category.name),
    thumbnail: item.thumbnail.imageKey,
    is_klarda: true,
    creator: item.user_id.profile_information.display_name,
    published_at: item.created_at,
    source_logo: item.user_id.profile_information.avatar
      ? `${IMAGE_CDN}${item.user_id.profile_information.avatar}`
      : logoBlog.src,
  };
};

const SearchEmpty = memo(({ searchText }: { searchText: string }) => (
  <div className="w-full items-center text-center">
    <Typography.Title level={4} className="mb-2">
      Không tìm thấy kết quả cho &quot;{searchText}&quot;
    </Typography.Title>
    <Empty description="Chúng tôi không tìm thấy nội dung phù hợp với từ khóa của bạn." />
    <Typography.Text>Vui lòng thử lại với từ khóa khác.</Typography.Text>
  </div>
));

SearchEmpty.displayName = "SearchEmpty";
SearchEmpty.displayName = "SearchEmpty";

const NewsList = memo(({ item, onClose }: { item: BlogData; onClose: () => void }) => {
  const { title, description, imageUrl } = processNewsData(convertToNewsResponse(item));

  const newsLink = `/tin-tuc/klarda/${item?.link}`;

  return (
    <div className="flex flex-col lg:flex-row gap-2" onClick={onClose}>
      <Link href={newsLink} passHref>
        <img
          src={imageUrl as string}
          alt={item.title || "News Image"}
          width={100}
          className="rounded h-[76px] min-w-[110px]"
        />
      </Link>
      <div className="flex flex-col w-full gap-1">
        <Typography.Link title={title} href={newsLink}>
          <Typography.Title level={5} className="line-clamp-1 text-sm mb-0">
            <div dangerouslySetInnerHTML={{ __html: title }} />
          </Typography.Title>
        </Typography.Link>
        <Typography.Link title={title} href={newsLink}>
          <Typography.Text title={description} type="secondary" className="line-clamp-1 text-[12px]">
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Typography.Text>
        </Typography.Link>
        <NewsInfo news={convertToNewsResponse(item)} />
      </div>
    </div>
  );
});
NewsList.displayName = "NewsList";

const SearchModal: React.FC<SearchModalProps> = memo(({ open, onClose }) => {
  const [searchText, setSearchText] = useState("");
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<InputRef>(null);
  const router = useRouter();

  const { data: newsData, isLoading: isLoadingNews } = useAxiosQuery<{ data: { posts: BlogData[] } }>({
    url: `/v1/blog`,
    method: "get",
    includeParamsInQueryKey: true,
    params: { search: searchText || "", limit: 5, status: "live", language: "vi" },
  });

  const { data: trendingData } = useAxiosQuery<{ data: { result: ITrendingData[] } }>({
    url: `/v1/admin/token/search/trending-public`,
    method: "get",
  });

  useEffect(() => {
    if (open) {
      // Focus input when modal opens
      setTimeout(() => inputRef.current?.focus(), 100);
    }
  }, [open]);

  const renderNews = useCallback(
    () => (
      <MyCard title="Tin tức" extra={<LinkToKlardaApp />} styles={{ body: { padding: 0, paddingTop: 16 } }}>
        <div className="space-y-2">
          {isLoadingNews ? (
            <Skeleton active paragraph={{ rows: 10 }} />
          ) : newsData?.data?.posts?.length ? (
            <>
              {newsData.data.posts.map(item => (
                <NewsList key={item.id} item={item} onClose={onClose} />
              ))}
              <Typography.Link href={`/tin-tuc/search`} className="block mt-2">
                {`Xem thêm tin tức >`}
              </Typography.Link>
            </>
          ) : (
            <SearchEmpty searchText={searchText} />
          )}
        </div>
      </MyCard>
    ),
    [isLoadingNews, newsData?.data?.posts, onClose, searchText],
  );

  const handleSearchTextChange = useMemo(() => debounce((text: string) => setSearchText(text), 500), []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    handleSearchTextChange(newValue);
  };

  const handleCloseSearch = () => {
    onClose();
    setInputValue("");
    handleSearchTextChange("");
  };
  return (
    <MyModal
      open={open}
      onCancel={handleCloseSearch}
      footer={null}
      hasHeader={false}
      width="fit-content"
      wrapClassName="max-w-[1037px]"
      bodyClassName="flex flex-col gap-6 p-[16px] h-[95vh] lg:h-fit lg:max-h-[95vh] overflow-hidden" // Changed to overflow-hidden
      centered={false}
      maskClosable
    >
      <div className="flex flex-col h-full">
        <div className="flex items-center gap-4 bg-white w-full py-2 z-10">
          <div onClick={() => router.push("/")} className="cursor-pointer w-fit">
            <KlardacomLogo />
          </div>
          <Input
            ref={inputRef}
            size="large"
            placeholder="Tìm kiếm ..."
            prefix={<SearchOutlined className="text-gray-400" />}
            className="rounded-full bg-white border-gray-300 text-gray-600 placeholder-gray-400 flex-1"
            onChange={handleInputChange}
            value={inputValue}
          />
          <Button size="large" type="primary" className="px-2" icon={<CloseOutlined />} onClick={handleCloseSearch} />
        </div>

        <div className="flex-1 overflow-y-auto mt-2">
          {renderNews()}
          <div className="flex items-center gap-3 flex-wrap bg-[#F4EFFF] rounded-lg p-4 mt-6">
            <Typography.Title level={5}>Tìm kiếm nhiều nhất</Typography.Title>
            {trendingData?.data?.result?.slice(0, 5).map(item => (
              <div key={item.name} className="w-fit bg-[#C0A2FB] text-primary text-sm rounded-lg px-2 py-1">
                {item.name}
              </div>
            ))}
          </div>
          <AdsBanner isSmallSize />
        </div>
      </div>
    </MyModal>
  );
});

SearchModal.displayName = "SearchModal";
export default SearchModal;
