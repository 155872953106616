import { useAuth } from "@/context/AuthContext";
import { LogoutOutlined, RightOutlined, SettingOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd/lib";
import { Avatar, Dropdown, Switch } from "antd/lib";
import type { PropsWithChildren } from "react";

export default function UserPopover({ children }: PropsWithChildren) {
  const { user, logout } = useAuth();

  const userSettingsItems: MenuProps["items"] = [
    {
      key: "account",
      label: (
        <div className="flex items-center bg-black bg-opacity-5 rounded-lg p-3 gap-3">
          <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=2" size="large" />
          <div className="min-w-[200px]">
            <div className="font-bold">{user?.username}</div>
            <div className="text-sm font-medium">Manage my account</div>
          </div>
          <RightOutlined className="ml-auto" />
        </div>
      ),
    },
    { type: "divider" },
    {
      key: "status",
      type: "group",
      label: <span className="text-black">My Status</span>,
      children: [
        {
          key: "basic-feature",
          label: (
            <div className="flex justify-between items-center">
              <span>Basic feature</span>
              <span className="text-green-500 ">•</span>
            </div>
          ),
          onClick: () => {
            window.open("https://dashboard.klarda.com/status", "_blank");
          },
        },
        {
          key: "user-status",
          label: (
            <div className="flex justify-between items-center">
              <span>Status</span>
              <span className="text-gray-500">Basic user</span>
            </div>
          ),
        },
      ],
    },
    { type: "divider" },
    {
      key: "webinars",
      label: <span className="text-black">Upcoming Webinars</span>,
      type: "group",
    },
    { type: "divider" },
    {
      key: "inventory",
      label: <span className="text-black">My Inventory</span>,
      type: "group",
    },
    { type: "divider" },
    {
      key: "depot",
      label: <span className="text-black">My Depot</span>,
      type: "group",
      children: [
        {
          key: "wallets",
          label: (
            <div className="flex justify-between items-center">
              <span>Wallets</span>
              <span className="text-gray-500">5</span>
            </div>
          ),
        },
        {
          key: "exchanges",
          label: (
            <div className="flex justify-between items-center">
              <span>Centralized Crypto Exchanges</span>
              <span className="text-gray-500">2</span>
            </div>
          ),
        },
      ],
    },

    { type: "divider" },
    {
      key: "portfolio",
      label: (
        <div className="flex justify-between items-center">
          <span>Portfolio Summary</span>
          <Switch size="small" />
        </div>
      ),
    },
    { type: "divider" },
    {
      key: "feedback",
      label: "Report/Feedback",
      itemIcon: <SettingOutlined />,
    },
    { type: "divider" },
    {
      key: "signout",
      label: "Sign out",
      itemIcon: <LogoutOutlined />,
      onClick: () => logout(),
    },
  ];

  return (
    <Dropdown menu={{ items: userSettingsItems }} trigger={["click"]} placement="bottomRight">
      {children}
    </Dropdown>
  );
}
